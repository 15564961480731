<template>
  <div class="app-wrapper">
    <div class="container py-5">
      <div class="col-lg-10 mx-auto p-0 app-wrapper__content">
        <div class="my-4 mb-5">
          <div
            class="py-4 d-flex align-items-center justify-content-between w-100"
          >
            <h3 class="ana_h3 ana_text-dark mb-0">Your Links</h3>
            <div class="d-flex align-items-center">
              <router-link
                to="/dash/YourLinks/Create"
                class="ana-btn ana-btn-dash ana-btn__fill"
                >Create New
              </router-link>
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkIndex",
};
</script>

<style></style>
